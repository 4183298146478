import React from 'react';
import './DottedLineLoader.css';
import { useTheme } from '@material-ui/core';

const loaderColors = {
    dark: '#d9d5e0',
    light: '#333',
};

const DottedLineLoader = () => {
  const theme = useTheme();
  const style = {
    backgroundColor: loaderColors[theme.palette.type],
  };
  return (
    <div className='dotted-line-spinner'>
      <div className='bounce1' style={style} />
      <div className='bounce2' style={style} />
      <div className='bounce3' style={style} />
    </div>
  );
};

export default DottedLineLoader;
