import React, { Suspense, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Slider,
  Button,
} from '@material-ui/core';
import ComponentLoader from './components/common/Loaders/ComponentLoader';
import Appbar from './components/common/Appbar/Appbar';
import { Alert } from '@material-ui/lab';
import './App.css';

const str_report = "Report an issue"

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: 'auto',
      // '@media (min-width: 1000px)': {
      //   width: '70vw',
      // },
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      height: '87vh',
      paddingTop: '50px',
      // overflow: 'auto',
      overflow: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardBox: {
      padding: '20px',
      paddingLeft: '30px',
      paddingRight: '30px',
      backgroundColor: 'white',
      width: '50%',
      height: '65%',
      borderRadius: '20px',
      boxShadow:
        theme.palette.type === 'light'
          ? '20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff'
          : '20px 20px 60px #565656, -20px -20px 60px #565656',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textarea: {
      border: 'grey 2px solid',
      width: '100%',
      marginTop: '20px',
      height: '70%',
      borderRadius: '10px',
      padding: '10px',
    },
    slider: {
      width: '97%',
      color: 'red'
    },
    selector: {
      opacity: '0.7',
      height: '25%',
      width: '100%',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginTop: '15px',
      paddingBottom: '15px'
    },
    sel: {
      height: '100%',
      width: '48%',
      borderRadius: '10px',
      cursor: 'pointer',
      border: '2px solid red',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'red',
      paddingTop: '5px',
      paddingBottom: '5px'
    },
    selected:{
      backgroundColor: 'red',
      color: 'white'
    },
    input: {
      height: '8%',
      width: '100%',
      borderRadius: '10px',
      border: 'grey 2px solid',
      padding: '10px',
      marginTop: '10px'
    }
  })
);

function App() {
  const classes = useStyles();
  const [slideValue, setSlideValue] = useState(3);
  const [email, setEmail] = useState('');
  const [textValue, setTextValue] = useState('');
  const [type, setType] = useState('Error');
  const [alerton, setAlerton] = useState(false);
  const handleSlideChange = (_e, newValue) => {
    setSlideValue(newValue);
  };
  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };

  const onReport = () => {
    setAlerton(true);
    // console.log({
    //   issue_type: type,
    //   email_contact: email,
    //   issue_desc: textValue,
    //   severity: slideValue
    // })
    fetch('https://us-central1-diary-a77f6.cloudfunctions.net/get_reportissue', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        issue_type: type,
        email_contact: email,
        issue_desc: textValue,
        severity: slideValue
      })
    }).then(() => {
      window.location.href = "https://daybook.app"
    });
  }
  return (
    <>
      <div className={classes.root}>
        <Suspense fallback={<ComponentLoader />}>
          <Appbar pageTitle={str_report} icon='guides' displayBackBtn />
        </Suspense>
        <div className={classes.content}>
          <h1 style={{
            fontSize: '40px'
          }}>report issue</h1>
          {alerton && (
            <Alert
              style={{ marginRight: '25px' }}
              onClose={() => {
                setAlerton(false);
              }}
              severity='success'
            >
              Thanks for reporting this problem
            </Alert>
          )}
          <div className={classes.cardBox}>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f0fff4',
              padding: '10px',
              borderRadius: '10px',
            }}>
            <Slider
              aria-labelledby='discrete-slider'
              defaultValue={3}
              valueLabelDisplay='auto'
              value={slideValue}
              onChange={handleSlideChange}
              step={1}
              marks
              min={1}
              max={5}
              className={classes.slider}
            />
            <div className={classes.selector}>
              <div onClick={() => {
                setType('Error');
              }} className={`${classes.sel} ${type === 'Error' && classes.selected}`} >Error</div>
              <div onClick={() => {
                setType('Security');
              }} className={`${classes.sel} ${type === 'Security' && classes.selected}`}>Security</div>
            </div>
            </div>
            <input placeholder="Contact Email" type="email" value={email} className={classes.input} onChange={(e) => {
                setEmail(e.target.value);
              }}></input>
            <textarea
              placeholder='Description of the issue'
              value={textValue}
              onChange={handleTextChange}
              className={classes.textarea}
            ></textarea>
            
            <Button
              color='primary'
              style={{ marginTop: '10px', backgroundColor: '#24CB3F' }}
              variant='contained'
              onClick={onReport}
            >
              Report
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;

