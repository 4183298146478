import React, { lazy, Suspense } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';

import ComponentLoader from '../Loaders/ComponentLoader';
// import MaterialIcon from '../MaterialIcon';

const AuthBtns = lazy(() => import('./AuthBtns'));
const LogoText = lazy(() => import('./LogoText'));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: '60px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
}));

// const iconMap: { [key in IconTypes]: string } = {
//   about: 'Info',
//   changelog: 'NewReleases',
//   download: 'Download',
//   printer: 'Print',
//   feedback: 'Feedback',
//   import: 'CloudUpload',
//   pricing: 'AttachMoney',
//   settings: 'Build',
//   templates: 'LayersOutlined',
//   wordcloud: 'Cloud',
// };

const Appbar = ({ pageTitle, displayBackBtn }) => {
  const classes = useStyles();
//   const location = useLocation();

  // const Icon = icons[icon];
  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar} color='default'>
        <Toolbar className='header' style={{ justifyContent: 'space-between' }}>
          <div className='flex items-center'>
            {/* {displayBackBtn && (
              <Link
                to='https://daybook.app'
              >
                <IconButton>
                  <ArrowBack />
                </IconButton>
              </Link>
            )} */}
            <Suspense fallback={<ComponentLoader />}>
              <LogoText />
            </Suspense>
          </div>
          <Typography className='ml1' variant='h6' color='inherit' style={{
            marginRight: '9.5%'
          }}>
            {/* <MaterialIcon icon={iconMap[icon]} /> */}
            <span>{pageTitle}</span>
          </Typography>
            <Suspense fallback={<ComponentLoader />}>
              <AuthBtns />
            </Suspense>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Appbar;
